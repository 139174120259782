import {
  Box,
  Circle,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import image1 from "../../Assets/app-employer-selection.png";
import image3 from "../../Assets/app-homepage-2.png";
import image2 from "../../Assets/app-hrms-login.png";
import CheckLeaveValueForm from "../CheckLeaveValueForm/CheckLeaveValueForm";
import Dialog from "../Dialog/Dialog";
import KeyFeatures from "../KeyFeatures/KeyFeatures";

const steps = [
  {
    number: 1,
    title: "Choose your employer",
    description:
      "Choose from the list of employers that are partnered with Keeper",
    imageSrc: image1,
    btnText: "Check your leave value ->",
  },
  {
    number: 2,
    title: "Login via your HR Software",
    description:
      "Just like you sign-in via google, here you sign-in via your HRMS by entering your HR portal's credentials.",
    imageSrc: image2,
  },
  {
    number: 3,
    title: "Apply in Minutes!",
    description:
      "You don't need to fill anything as everything is fetched from your HR Software. Just click and it's done.",
    imageSrc: image3,
  },
];

export default function StartedWithKeeper() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        as="section"
        py={{ base: 7, md: 12 }}
        maxW={"1280px"}
        margin={"auto"}
        px={{ base: 0, md: 5 }}
        overflow="hidden"
      >
        <VStack spacing={{ base: 10, md: 12 }}>
          <VStack spacing={{ base: 5, md: 7, lg: 10 }} textAlign="center">
            <Heading
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="normal"
              px={{ base: 5, md: 0 }}
            >
              Getting started with{" "}
              <Text fontWeight={"bold"}>Keeper is easy!</Text>{" "}
            </Heading>

            <KeyFeatures />
          </VStack>

          <VStack
            spacing={{ base: 10, md: 14 }}
            width="full"
            px={{ base: 5, md: 0 }}
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                {...step}
                isReversed={index % 2 !== 0}
                onOpen={onOpen}
              />
            ))}
          </VStack>
        </VStack>
      </Box>
      <Dialog isOpen={isOpen} onClose={onClose}>
        <CheckLeaveValueForm onClose={onClose} />
      </Dialog>
    </>
  );
}

function Step({
  number,
  title,
  description,
  imageSrc,
  btnText,
  isReversed = false,
  onOpen,
}) {
  return (
    <Flex
      direction={{
        base: "column-reverse",
        md: isReversed ? "row-reverse" : "row",
      }}
      gap={{ base: 8, md: 12 }}
      align="center"
      w={"full"}
      justify={"space-between"}
    >
      <VStack flex="1" align={{ base: "center", md: "flex-start" }} spacing={2}>
        <Circle
          size="36px"
          bg="primary"
          color="white"
          fontSize="lg"
          fontWeight="bold"
        >
          {number}
        </Circle>
        <VStack align={{ base: "center", md: "flex-start" }} spacing={3}>
          <Heading size="lg">{title}</Heading>
          <Text
            textAlign={{ base: "center", md: "left" }}
            w={{ base: "calc(100% - 50px)", md: "350px" }}
          >
            {description}
          </Text>
          {btnText && (
            <Text
              color="primary"
              textDecor={"underline"}
              textUnderlineOffset={4}
              textDecorationColor={"primary"}
              _hover={{ textDecoration: "none" }}
              onClick={onOpen}
              cursor="pointer"
            >
              {btnText}
            </Text>
          )}
        </VStack>
      </VStack>
      <Flex w={"50%"} justify={"center"}>
        <Box

        // transform={{ base: "none", md: "rotate(-5deg)" }}
        // transition="transform 0.3s ease"
        // _hover={{ transform: "rotate(0deg)" }}
        >
          <Image src={imageSrc} alt={title} width="full" height="auto" />
        </Box>
      </Flex>
    </Flex>
  );
}
