import { Box } from "@chakra-ui/react";
import AnimatedNumber from "../../Components/AnimatedNumber/AnimatedNumber";
import BrandBar from "../../Components/Brands/BrandBar";
import { TrustedBy } from "../../Components/Brands/TrustedBy";
import Container from "../../Components/Container/Container";
import FinancialStep from "../../Components/FinancialStep/FinancialStep";
import FreePlan from "../../Components/FreePlan/FreePlan";
import HeroCarousel from "../../Components/HeroCarousel/HeroCarousel";
import KeepInTouch from "../../Components/KeepInTouch/KeepInTouch";
import Testimonials from "../../Components/Testimonials/Testimonials";
import WhatDo from "../../Components/WhatDo/WhatDo";
import useDynamicTitle from "../../Hooks/useDynamicTitle";
import FAQ from "../FAQ/FAQ";

export default function Home() {
  useDynamicTitle("Turn unused leaves into cash!");
  return (
    <>
      <Container maxW="1280px">
        <Box
          h={{ base: "auto", md: "auto", lg: "calc(100vh - 105px)" }}
          pb={{ base: 5, md: 10 }}
        >
          <HeroCarousel />
          <BrandBar />
        </Box>
        <WhatDo />
        <FinancialStep />
      </Container>
      <TrustedBy />
      <Container maxW="1280px">
        <AnimatedNumber />
        <Testimonials />
        <FreePlan />
        <FAQ />
        <KeepInTouch />
      </Container>
    </>
  );
}
