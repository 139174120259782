import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import appHomeImage from "../../Assets/app-homepage-3.png";

export default function ExploreEmployers() {
  return (
    <Box as="section" py={{ base: 7, md: 12 }} overflow="hidden">
      <Flex
        direction={{ base: "column-reverse", lg: "row" }}
        gap={{ base: 6, md: 12, lg: 16 }}
        align="center"
      >
        <VStack
          align={{ base: "center", lg: "flex-start" }}
          spacing={{ base: 3, md: 5, lg: 6 }}
          flex="1"
          textAlign={{ base: "center", lg: "left" }}
        >
          <Heading
            as="h1"
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            fontWeight="bold"
            lineHeight="shorter"
          >
            Employers, offer Keeper to your employees and increase the ROI of
            your benefit!
          </Heading>

          <Text fontSize={{ base: "base", md: "lg" }} maxW="lg">
            Offer a solution that adds value and sparks interest in transforming
            this overlooked resource
          </Text>

          <Text
            as={Link}
            to="/"
            _hover={{
              textDecoration: "none",
              borderBottomColor: "transparent",
            }}
            borderBottomWidth={1}
            borderBottomColor={"primary"}
          >
            <Button
              rightIcon={<FaArrowRight className="w-4 h-4" />}
              variant="link"
              color="primary"
              fontWeight="semibold"
              fontSize="lg"
              _hover={{ textDecoration: "none" }}
            >
              Explore Keeper for Employers
            </Button>
          </Text>
        </VStack>

        <Box flex="1" position="relative">
          <Box maxW="300px" mx="auto">
            <Box>
              <Image
                src={appHomeImage}
                alt="App Homepage"
                width="full"
                height="auto"
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
