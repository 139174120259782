import { HStack, Stack, Text } from "@chakra-ui/react";
import CustomListHeader from "../../CustomListHeader";

const arrayOfInfo = [
  "ONIONKNIGHTS SOFTWARE PRIVATE LIMITED",
  "Address: Plot No 20, Block- H-1/a, First Floor, Seat No -ff26, Sector 63, Noida, Gautam Buddha Nagar, Noida, Uttar Pradesh, India, 201301",
  "CIN: U62099UP2023PTC182152",
  "Contact number: +91 8439309979",
  "Grievance Officer: Sushant Mehta",
];

export default function CompanyDetails() {
  return (
    <Stack>
      <CustomListHeader customStyle={{ textTransform: "uppercase" }}>
        Company Details
      </CustomListHeader>
      {arrayOfInfo.map((info, i) => (
        <HStack key={i}>
          <Text
            textTransform={"capitalize"}
            transition={"all 0.3s ease"}
            fontWeight={"normal"}
            color={"gray.400"}
          >
            {info}
          </Text>
        </HStack>
      ))}
    </Stack>
  );
}
