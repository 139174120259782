import { HStack, Icon, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import CustomListHeader from "../../CustomListHeader";

const linkHeader = ["general", "company", "legal", "follow us"];

const generalLinks = [
  { title: "contact us", href: "/contact-us" },
  { title: "faq", href: "/faq" },
  { title: "delete account", href: "/delete-account" },
];

const companyLinks = [
  { title: "about us", href: "/" },
  { title: "Our Lending Partners", href: "/business-partner" },
];

const legalLinks = [
  { title: "terms & conditions", href: "/terms-conditions" },
  { title: "privacy policy", href: "/privacy-policy" },
  // { title: "refund policy", href: "/refund-policy" },
];

const socialLinks = [
  {
    title: "linkedin",
    icon: FaLinkedin,
    href: "https://www.linkedin.com/company/keeperfinance/",
  },
];

export const FooterLinks = () => {
  return (
    <>
      {[generalLinks, companyLinks, legalLinks, socialLinks]?.map(
        (links, i) => (
          <Stack key={i}>
            <CustomListHeader customStyle={{ textTransform: "uppercase" }}>
              {linkHeader[i]}
            </CustomListHeader>
            {links.map((link, i) => (
              <HStack key={i}>
                {link?.icon && (
                  <Icon as={link?.icon} h={4} w={4} color={"gray.500"} />
                )}
                <Text
                  as={Link}
                  to={link.href}
                  textTransform={"capitalize"}
                  transition={"all 0.3s ease"}
                  fontWeight={"normal"}
                  color={"gray.400"}
                  _hover={{ color: "gray.50" }}
                >
                  {link.title}
                </Text>
              </HStack>
            ))}
          </Stack>
        )
      )}
    </>
  );
};
