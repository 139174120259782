import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdAlert, IoMdClose } from "react-icons/io";
import formatNumberWithCommas from "../../utilities/formatNumberWithCommas";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?!.*\b(gmail|yahoo|outlook|hotmail|aol|icloud|zoho|yandex|protonmail|live|msn|gmx|mail|rediffmail)\.com$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TELEGRAM_BOT_API_URL = process.env.REACT_APP_TELEGRAM_BOT_API_URL;

function calculateLeaveValue(totalAmount) {
  return Math.floor(totalAmount * 0.6);
}

export default function CheckLeaveValueForm({ onClose }) {
  const [state, setState] = useState("initial");
  const [step, setStep] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    if (step === 1) {
      setStep(2);
      return;
    }
    const contactInfo = { email: data.email, phone: data.phone };
    localStorage.setItem("contactInfo", JSON.stringify(contactInfo));
    setState("calculating");
    const payload = {
      chat_id: "-908107678",
      text: `Leave check request\n\nEmail: ${data.email}${
        data.phone ? `\nPhone: +91${data.phone}` : ""
      }\nMonthly Salary: ₹${formatNumberWithCommas(data.salary)}`,
    };
    try {
      const response = await axios.post(TELEGRAM_BOT_API_URL, payload);
      if (response.data.ok) {
        setStep((prev) => prev + 1);
        setState("success");
      }
    } catch (error) {
      setState("error");
      console.error(error);
    }
  };

  const resetForm = () => {
    setValue("salary", ""); // Reset salary field
    setState("initial");
    setStep(1);
  };

  useEffect(() => {
    const contactInfo = localStorage.getItem("contactInfo");
    const { phone, email } = JSON.parse(contactInfo || "{}");
    setValue("phone", phone);
    setValue("email", email);
  }, [setValue]);

  const handleOnClose = () => {
    if (step === 1 || step === 3) {
      onClose();
    } else {
      onSubmit(getValues());
    }
  };
  return (
    <>
      <DialogHeading handleOnClose={handleOnClose} step={step} />
      {step === 3 ? (
        <>
          <VStack spacing={5}>
            <CalculatedLeaveValue
              leaveValue={calculateLeaveValue(getValues("salary"))}
            />
            <Button
              variant="solid"
              type="submit"
              bg="primary"
              color="white"
              onClick={resetForm}
              w={"full"}
              _hover={{ bg: "#5050d8" }}
            >
              Check Another Leave Value
            </Button>
          </VStack>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5}>
            {step === 1 && (
              <>
                <FormControl id="email">
                  <FormLabel>Work Email</FormLabel>
                  <InputGroup>
                    <Input
                      type="email"
                      size="md"
                      placeholder="ramesh@yourcompanyname.com"
                      focusBorderColor="primary"
                      _hover={{ borderColor: "primary" }}
                      borderColor={errors.email && "red.600"}
                      {...register("email", {
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                    {errors.email && (
                      <InputRightElement pointerEvents="none">
                        <Icon as={IoMdAlert} fontSize={"lg"} color="red.600" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {errors.email && (
                    <Text mt={2} color={"red.600"}>
                      Email is Invalid
                    </Text>
                  )}
                </FormControl>
                <FormControl id="salary">
                  <FormLabel>Your Monthly Salary</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>₹</InputLeftAddon>
                    <Input
                      type="number"
                      size="md"
                      inputMode="numeric"
                      placeholder="50000"
                      focusBorderColor="primary"
                      _hover={{ borderColor: "primary" }}
                      borderColor={errors.salary && "red.600"}
                      {...register("salary", {
                        required: true,
                        pattern: /^[0-9]*$/,
                      })}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.salary && (
                      <InputRightElement pointerEvents="none">
                        <Icon as={IoMdAlert} fontSize={"lg"} color="red.600" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {errors.salary && (
                    <Text mt={2} color={"red.600"}>
                      Monthly Salary is Invalid
                    </Text>
                  )}
                </FormControl>
              </>
            )}
            {step === 2 && (
              <FormControl id="phone">
                <FormLabel>Contact Number</FormLabel>
                <InputGroup>
                  <InputLeftAddon>+91</InputLeftAddon>
                  <Input
                    type="number"
                    size="md"
                    inputMode="numeric"
                    focusBorderColor="primary"
                    _hover={{ borderColor: "primary" }}
                    borderColor={errors.phone && "red.600"}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    {...register("phone", {
                      required: true,
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Phone number is invalid",
                      },
                    })}
                  />
                  {errors.phone && (
                    <InputRightElement pointerEvents="none">
                      <Icon as={IoMdAlert} fontSize={"lg"} color="red.600" />
                    </InputRightElement>
                  )}
                </InputGroup>
                {errors.phone && (
                  <Text mt={2} color={"red.600"}>
                    Phone Number is Invalid
                  </Text>
                )}
              </FormControl>
            )}
            <FormControl id="button">
              <Button
                variant="solid"
                type={"submit"}
                bg="primary"
                color="white"
                _hover={{}}
                isLoading={state === "calculating" && step === 2}
                isDisabled={
                  state === "calculating" || errors.email || errors.salary
                }
                w={"full"}
              >
                {state === "calculating"
                  ? "Calculating..."
                  : step === 1
                  ? "Next"
                  : "Check Leave Value"}
              </Button>
            </FormControl>
          </VStack>
        </form>
      )}
    </>
  );
}

const CalculatedLeaveValue = ({ leaveValue }) => {
  return (
    <VStack spacing={0} bg={"#f7f7ff"} py={5} rounded={"lg"} w={"full"}>
      <Text fontSize={"lg"} fontWeight={"semibold"}>
        You have
      </Text>
      <Text fontSize={"5xl"} fontWeight={"semibold"} color={"primary"}>
        ~₹{formatNumberWithCommas(leaveValue) || 0}
      </Text>
      <Text fontSize={"lg"} fontWeight={"semibold"}>
        in paid leaves
      </Text>
    </VStack>
  );
};

const DialogHeading = ({ handleOnClose, step }) => {
  return (
    <Flex justify={"space-between"} align={"start"} mb={5} pt={3}>
      <Heading size="md" flex={1} fontWeight={"semibold"}>
        {step === 3
          ? "Calculated Leave balance"
          : "Check your leave balance now"}
      </Heading>
      <Box>
        <IconButton
          onClick={handleOnClose}
          aria-label="Back"
          fontSize={"2xl"}
          variant={"ghost"}
          fontWeight={"thin"}
          size={"sm"}
          color={"gray.500"}
          mr={-4}
          mt={-6}
        >
          <Icon as={IoMdClose} />
        </IconButton>
      </Box>
    </Flex>
  );
};
