import {
  Box,
  Circle,
  Grid,
  Heading,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { forEmployer } from "./Data/faqData";

import { FaQuestion } from "react-icons/fa";

function FAQItem({ question, answer, i }) {
  return (
    <VStack align="flex-start" spacing={4} maxW={"350px"} h={"full"} w={"full"}>
      <Circle
        size="40px"
        bg={"#f7f7ff"}
        fontFamily={"garamond"}
        fontSize={{ base: "base", lg: "lg", xl: "xl", "2xl": "2xl" }}
        borderWidth={1}
        borderColor={"#9291EF"}
      >
        <Icon as={FaQuestion} color={"#9291EF"} />
      </Circle>

      <VStack align="flex-start" spacing={"1px"}>
        <Text fontSize="lg" fontWeight="semibold" color={"primary"}>
          {question}
        </Text>
        <Text fontSize="sm">{answer}</Text>
      </VStack>
    </VStack>
  );
}

export default function FAQ() {
  return (
    <>
      <Box as="section" py={{ base: 7, md: 12 }}>
        <VStack spacing={{ base: 8, md: 12 }}>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="bold"
            textAlign="center"
            color={"primary"}
          >
            FAQs
          </Heading>

          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
            }}
            gap={{ base: 8, md: 12 }}
            placeItems={{ base: "flex-start", md: "center" }}
            w={"full"}
            margin={"0 auto"}
          >
            {forEmployer.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                i={index}
              />
            ))}
          </Grid>
        </VStack>
      </Box>
    </>
  );
}
