import { Text } from "@chakra-ui/react";
import React from "react";

const CustomListHeader = ({ children, customStyle }) => {
  return (
    <Text
      fontWeight={"bold"}
      fontSize={{ md: "md", "2xl": "lg" }}
      color={"primary.700"}
      sx={customStyle}
    >
      {children}
    </Text>
  );
};

export default CustomListHeader;
