import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";
import QuickContact from "../Components/QuickContact/QuickContact";
import ScrollTop from "../utilities/ScrollToTop/ScrollTop";
ReactGA.initialize(process.env.REACT_APP_measurmentId);
const Main = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  return (
    <>
      <ScrollTop>
        <Navbar />
        <Outlet />
        <QuickContact />
        <Footer />
      </ScrollTop>
    </>
  );
};

export default Main;
