import { Box, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { FaQuoteLeft } from "react-icons/fa";
import thumbnail1 from "../../Assets/testmonial_thumb_1.png";
import thumbnail2 from "../../Assets/testmonial_thumb_2.png";

export default function Testimonials() {
  const testimonials = [
    {
      quote:
        "It’s great to see employer going an extra mile to help their employees which is above their core business",
      author: "Gourav Lal",
      role: "ABP Network",
      image: thumbnail1,
    },
    {
      quote:
        "Every thing with Keeper was explained very clearly. In these days, there are so many frauds, but as it was from my employer, I was satisfied.",
      author: "Lovey Gumber",
      role: "ABP Network",
      image: thumbnail2,
    },
  ];

  return (
    <>
      <Box as="section" py={{ base: 7, md: 12 }}>
        <VStack spacing={{ base: 8, md: 12, lg: 16 }}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              testimonial={testimonial}
              index={index}
              key={Math.random() * 555}
            />
          ))}
        </VStack>
      </Box>
    </>
  );
}

function TestimonialCard({ testimonial, index }) {
  return (
    <Flex
      key={index}
      direction={{
        base: "column",
        md: index % 2 === 0 ? "row" : "row-reverse",
      }}
      gap={{ base: 6, md: 16 }}
      align="center"
    >
      <Box
        flex="1"
        bg="gray.200"
        rounded="lg"
        overflow="hidden"
        position="relative"
        boxSize={{ base: "full", md: "300px" }}
        maxW={{ base: "auto", md: "auto", lg: "600px" }}
      >
        <Image
          src={testimonial.image}
          alt=""
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box>

      <VStack flex="1" align={{ base: "center", md: "flex-start" }}>
        <Icon as={FaQuoteLeft} color={"primary"} fontSize={"2xl"} mb={-1} />

        <Text
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight="semibold"
          textAlign={{ base: "center", md: "left" }}
        >
          {testimonial.quote}
        </Text>
        <VStack spacing={0.2} align={{ base: "center", md: "flex-start" }}>
          <Text
            fontWeight="bold"
            color={"primary"}
            fontSize={{ base: "xl", md: "2xl" }}
          >
            {testimonial.author}
          </Text>
          <Text fontSize="sm">{testimonial.role}</Text>
        </VStack>
      </VStack>
    </Flex>
  );
}
