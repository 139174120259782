export default function formatNumberWithCommas(number) {
  // Handle null, undefined, or invalid input
  if (number === null || number === undefined) {
    return '';
  }

  // Convert to number if string is passed
  const num = typeof number === 'string' ? Number(number) : number;

  // Check if conversion resulted in a valid number
  if (isNaN(num)) {
    return '';
  }

  // Handle negative numbers
  const isNegative = num < 0;
  const absNum = Math.abs(num);

  // Convert to string and handle decimals
  const [integerPart, decimalPart] = absNum.toString().split('.');

  // Format integer part with commas
  const x = integerPart;
  const lastThree = x.slice(-3);
  const otherNumbers = x.slice(0, -3);
  const formattedOtherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  const formattedIntegerPart = otherNumbers ? formattedOtherNumbers + ',' + lastThree : lastThree;

  // Combine all parts
  let result = isNegative ? '-' : '';
  result += formattedIntegerPart;
  if (decimalPart) {
    result += '.' + decimalPart;
  }

  return result;
}
