import { Box, Flex, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { FaMailBulk, FaMapPin, FaPhone } from "react-icons/fa";
import { ContactUsForm } from "../ContactUsForm/ContactUsForm";

export default function KeepInTouch() {
  return (
    <Box as="section" py={{ base: 7, md: 12 }}>
      <Flex
        direction={{ base: "column", lg: "row" }}
        gap={{ base: 12, md: 12, lg: 32 }}
      >
        <VStack align="flex-start" spacing={{ base: 3, md: 5, lg: 8 }} flex={1}>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="bold"
            color={"primary"}
          >
            Get in Touch
          </Heading>
          <Text fontSize="lg">
            We'd love to hear from you. Please fill out this form or shoot us an
            email.
          </Text>
          <VStack
            align="flex-start"
            spacing={4}
            width="full"
            display={{ base: "none", md: "none", lg: "block" }}
          >
            <Flex align="center" gap={4}>
              <Icon as={FaMailBulk} color={"primary"} />
              <Text>hello@keeper.co.in</Text>
            </Flex>
            <Flex align="center" gap={4}>
              <Icon as={FaPhone} color={"primary"} />
              <Text>+91 8439309979</Text>
            </Flex>
            <Flex align="center" gap={4}>
              <Icon as={FaMapPin} color={"primary"} />
              <Text>
                Plot No 20, Block- H-1/a, First Floor, Seat No -ff26, Sector 63,
                Noida, Gautam Buddha Nagar, Noida, Uttar Pradesh, India, 201301
              </Text>
            </Flex>
          </VStack>
        </VStack>

        <Box w={{ base: "auto", md: "auto", lg: "40%" }}>
          <ContactUsForm />
        </Box>
      </Flex>
    </Box>
  );
}
