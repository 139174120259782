import { Box, Grid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import calendarImg from "../../Assets/banner-calendar.png";
import leavesImg from "../../Assets/banner-leaves.png";
import amountImg from "../../Assets/banner-money.png";
import paidLeavesImg from "../../Assets/banner-paid-leaves.png";
import Hero from "../../Page/Home/Hero/Hero";

const heroContents = [
  {
    title: "The ROI of paid leaves as benefit is low",
    btnText: "Learn More",
    link: "/contact-us",
    image: calendarImg,
    highlightWord: "low",
  },
  {
    title: "Paid leave is a costly benefit for employers",
    subtitle:
      "An employer spends Rs. 35,000 per employee on paid leave every year",
    btnText: "Increase your ROI of Paid Leaves",
    link: "/contact-us",
    image: amountImg,
    highlightWord: "costly benefit",
  },
  {
    title: "It is an underutilized benefit",
    subtitle: "72% of paid leaves go unused every year",
    btnText: "Let’s connect today!",
    link: "/contact-us",
    image: leavesImg,
    highlightWord: "underutilized",
  },
  {
    title: "Paid leave is an underappreciated employee benefit.",
    subtitle:
      "The cash value of paid leaves is locked till they leave the organization",
    btnText: "Let’s connect today!",
    link: "/contact-us",
    image: paidLeavesImg,
    highlightWord: "underappreciated",
  },
];

export default function HeroCarousel() {
  const carouselRef = useRef(null);
  const [activeCarousel, setActiveCarousel] = useState(0);
  let settings = {
    dots: true,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    cssEase: "linear",
    beforeChange: (current, next) => {
      setActiveCarousel(next);
    },
    appendDots: (dots) => (
      <Box
        pos={"absolute"}
        bottom={{ base: -10, md: 0, "2xl": 5 }}
        p={"10px"}
        // style={{
        //   padding: "10px",
        //   position: "absolute",
        //   bottom: 5,
        // }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </Box>
    ),
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.slickPlay();
    }
  }, [carouselRef]);

  return (
    <>
      <Box
        bg={"#f7f7ff"}
        borderRadius={"xl"}
        pos={"relative"}
        paddingBottom={{ base: 10, md: 0 }}
        h={"full"}
      >
        <Slider {...settings} ref={carouselRef}>
          {heroContents.map((content, i) => (
            <Grid
              key={content.title}
              pos={"relative"}
              zIndex={activeCarousel === i ? 50 : 0}
              h={{ base: "auto", md: "auto", lg: "calc(100vh - 145px)" }}
              placeItems={"center"}
            >
              <Hero {...content} />
            </Grid>
          ))}
        </Slider>
      </Box>
    </>
  );
}
