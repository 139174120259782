import {
  Box,
  Heading,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import moneyBagImg from "../../Assets/money_bag.png";
import TextCarousel from "../TextCarousel/TextCarousel";

export default function WhatDo() {
  return (
    <Box
      as="section"
      py={{ base: 2, md: 8 }}
      pt={{ base: 0, md: 12, lg: 16, xl: 20 }}
      overflow={"hidden"}
    >
      <VStack spacing={{ base: 3, md: 3, lg: 3 }} textAlign="center">
        <Heading
          as="h1"
          size="xl"
          maxW={"xl"}
          fontWeight="bold"
          color={"primary"}
        >
          What does Keeper do?
        </Heading>

        <Text fontSize={"lg"} maxW="2xl" lineHeight="tall">
          Keeper unlocks the cash value of employee's paid leaves while they are
          still employed with you.
        </Text>

        <Box position="relative" maxW="sm">
          <Image
            src={moneyBagImg}
            alt=""
            width="full"
            height="auto"
            filter={useColorModeValue("none", "brightness(0.9)")}
          />
          <HStack justify="end" mr={-8} mt={-8} className="financial-section">
            <TextCarousel
              slidesToShow={1}
              width={"200px !important"}
              speed={false}
              autoplaySpeed={3000}
              cssEase="normal"
            >
              {["Gas", "Doctor's Visit", "Emergency", "Groceries", "Bills"].map(
                (feature, i) => (
                  <Box key={feature} px={5} w={"fit-content"}>
                    <Text fontSize={"lg"} fontWeight={"light"}>
                      {feature}
                    </Text>
                  </Box>
                )
              )}
            </TextCarousel>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
}
