import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import manImg from "../../Assets/man-1.png";
import { PrimaryButton } from "../../Components/PrimaryButton";
import CheckLeaveValueForm from "../CheckLeaveValueForm/CheckLeaveValueForm";
import Dialog from "../Dialog/Dialog";
export default function UnusedPaidLeaves() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box borderRadius={"xl"} pos={"relative"} py={{ base: 7, md: 12 }}>
        <Flex
          justify={"space-between"}
          flexDir={{ base: "column-reverse", md: "column", lg: "row" }}
          gap={{ base: 7, md: 10, lg: 0 }}
        >
          <VStack
            alignItems={{ base: "center", md: "flex-start" }}
            spacing={{ base: 3, md: 5, lg: 7 }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              maxW="600px"
            >
              Got unused paid leaves?
            </Heading>
            <Text fontSize={{ base: "base", md: "lg" }} maxW="lg">
              Get unfair advantage at every step of your financial journey by
              unlocking the hidden value of paid leaves.
            </Text>

            <PrimaryButton onClick={onOpen}>
              Calculate your leave value
            </PrimaryButton>
          </VStack>
          <Flex w={{ base: "full", md: "full", lg: "50%" }} justify={"center"}>
            <Box h={{ base: "300px", md: "320px", lg: "420px" }}>
              <Image
                src={manImg}
                alt="App Home Interface"
                w={"full"}
                h={"full"}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Dialog isOpen={isOpen} onClose={onClose}>
        <CheckLeaveValueForm onClose={onClose} />
      </Dialog>
    </>
  );
}
