import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import formatNumberWithCommas from "../../utilities/formatNumberWithCommas";

export default function AnimatedNumber() {
  const [number, setNumber] = useState(479141);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber((prevNumber) => prevNumber + Math.random() * 100);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box textAlign={"center"} pb={{ base: 7, md: 12 }}>
      <Text
        fontSize={{ base: "5xl", md: "5xl", lg: "9xl" }}
        fontWeight="normal"
        fontFamily={"garamond"}
        pos={"relative"}
        display={"inline-block"}
      >
        <Text
          fontSize={{ base: "2xl", md: "2xl", lg: "6xl" }}
          pos={"absolute"}
          left={-3}
          top={3}
          fontWeight={"semibold"}
        >
          ₹
        </Text>
        {formatNumberWithCommas(number.toFixed(2))}
      </Text>
      <Text fontSize="lg" color="primary">
        Interest saved with Keeper*
      </Text>
    </Box>
  );
}
