import { Box } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export default function TextCarousel({
  children,
  slidesToShow = 2,
  speed = 2000,
  autoplaySpeed = 2000,
  cssEase = "linear",
  ...props
}) {
  const settings = {
    className: "slider variable-width center",
    centerMode: true,
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: cssEase,
    variableWidth: true,
  };
  return (
    <Box
      className="slider-container"
      pos={"relative"}
      w={"calc(100vw + 300px)"}
      {...props}
    >
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
}
