import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import whatsappLogo from "../../Assets/whatsapp-logo.png";
import Container from "../Container/Container";
import { CustomSectionHeader } from "../CustomSectionHeader";
import { PrimaryButton } from "../PrimaryButton";

export default function QuickContact() {
  return (
    <>
      <Box bg={"#F9F9F9"} py={{ base: 10, md: 14, lg: 16 }}>
        <Container>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
            margin={"auto"}
            w={{ base: "full", md: "full", lg: "80%", "2xl": "full" }}
            rowGap={5}
          >
            <Box>
              <CustomSectionHeader
                borderDisplay={"none"}
                headingStyle={{ textAlign: { base: "center", md: "left" } }}
              >
                Quickly resolve your queries
              </CustomSectionHeader>
              <Text mt={2} textAlign={{ base: "center", md: "left" }}>
                We’re available on working days from 9:30 AM to 6:30 PM
              </Text>
            </Box>
            <Box
              w={{ base: "full", md: "initial" }}
              as={Link}
              to={"https://wa.me/+918439309979"}
              target="_blank"
            >
              <PrimaryButton
                customStyle={{
                  bg: "transparent",
                  border: "2px",
                  borderColor: "primary",
                  borderRadius: "full",
                  color: "primary",
                  shadow: "none",
                  _hover: { color: "gray.100", bg: "primary" },
                  w: { base: "full", md: "initial" },
                }}
              >
                <Image
                  src={whatsappLogo}
                  objectFit={"contain"}
                  w={{ base: "27px", md: "full" }}
                />
                Chat With Us
              </PrimaryButton>
            </Box>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
