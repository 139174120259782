import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { addDoc, collection } from "firebase/firestore";
import { marked } from "marked";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../Components/Container/Container";
import { db } from "../../Firebase/firebase.config";

export default function CreateBlog() {
  const [title, setTitle] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState("");
  const [date, setDate] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const markdownToHtml = marked.parse(details);
    const blogContent = {
      title,
      thumbnail,
      description,
      details: markdownToHtml,
      publishedAt: date,
    };

    try {
      const docRef = await addDoc(collection(db, "blogs"), blogContent);
      if (docRef?.id) {
        alert("Blog Successfully Created");
        navigate("/blogs");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <Container>
      <Box borderRadius="lg" p={{ sm: 5, md: 5, lg: 16 }}>
        <Box p={4}>
          <Box>
            <Heading>Create New Blog</Heading>
          </Box>
          <Box bg="white" borderRadius="lg">
            <Box mt={5} color="#0B0E3F">
              <form onSubmit={handleSubmit}>
                <VStack spacing={5}>
                  <FormControl id="name">
                    <FormLabel>Title</FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                      <Input
                        type="text"
                        size="md"
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Blog title"
                        isRequired
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl id="name">
                    <FormLabel>Thumbnail</FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                      <Input
                        type="text"
                        size="md"
                        onChange={(e) => setThumbnail(e.target.value)}
                        placeholder="Blog thumbnail URL"
                        isRequired
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl id="name">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      borderColor="gray.300"
                      _hover={{
                        borderRadius: "gray.300",
                      }}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Write blog description"
                      isRequired
                    />
                  </FormControl>
                  <FormControl id="name">
                    <FormLabel>Details</FormLabel>
                    <Textarea
                      borderColor="gray.300"
                      _hover={{
                        borderRadius: "gray.300",
                      }}
                      onChange={(e) => setDetails(e.target.value)}
                      placeholder="Write here about blog"
                      isRequired
                    />
                  </FormControl>

                  <FormControl id="name">
                    <FormLabel>Published Date</FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                      <Input
                        type="text"
                        size="md"
                        isRequired
                        placeholder="Nov 4, 2023"
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl id="name" float="right">
                    <Button
                      variant="solid"
                      bg="primary"
                      color="white"
                      _hover={{}}
                      type="submit"
                    >
                      Post
                    </Button>
                  </FormControl>
                </VStack>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
