import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import Main from "../Layout/Main";
import BusinessPartner from "../Page/BusinessPartner/BusinessPartner";
import CreateBlog from "../Page/CreateBlog/CreateBlog";
import DeleteAccount from "../Page/DeleteAccount/DeleteAccount";
import { ErrorPage } from "../Page/ErrorPage/ErrorPage";
import ForEmployees from "../Page/ForEmployees/ForEmployees";
import Home from "../Page/Home/Home";
import PrivacyPolicy from "../Page/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../Page/RefundPolicy/RefundPolicy";
import TermsConditions from "../Page/TermsConditions/TermsConditions";
const SingleBlog = React.lazy(() => import("../Page/SingleBlog/SingleBlog"));
const ContactUs = React.lazy(() => import("../Page/ContactUs/ContactUs"));
const ForEmployer = React.lazy(() => import("../Page/ForEmployer/ForEmployer"));
const FAQ = React.lazy(() => import("../Page/FAQ/FAQ"));
const Blog = React.lazy(() => import("../Page/Blog/Blog"));
const Download = React.lazy(() => import("../Page/Download/Download"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/home", element: <Home /> },
      {
        path: "/faq",
        element: (
          <Suspense fallback={<Loader />}>
            <FAQ />
          </Suspense>
        ),
      },
      { path: "/for-employees", element: <Suspense fallback={<Loader />}><ForEmployees /></Suspense> },
      // {
      //   path: "/for-employers",
      //   element: (
      //     <Suspense>
      //       <ForEmployer />
      //     </Suspense>
      //   ),
      // },
      {
        path: "/contact-us",
        element: (
          <Suspense fallback={<Loader />}>
            <ContactUs />
          </Suspense>
        ),
      },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/refund-policy", element: <RefundPolicy /> },
      { path: "/terms-conditions", element: <TermsConditions /> },
      { path: "/delete-account", element: <DeleteAccount /> },
      { path: "/business-partner", element: <BusinessPartner /> },
      {
        path: "/blogs",
        element: (
          <Suspense fallback={<Loader />}>
            <Blog />
          </Suspense>
        ),
      },
      {
        path: "/blog/:blogId",
        element: (
          <Suspense fallback={<Loader />}>
            <SingleBlog />
          </Suspense>
        ),
      },
      { path: "/blog/create", element: <CreateBlog /> },
      { path: "/download", element: <Download /> },
    ],
  },
]);
